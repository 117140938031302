import { useEffect, useState } from 'react'
import API from './api'
//import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
//import { addTodo } from "redux"

import './styles/all.scss'
import './App.css';
import Main from './Main'
import Nav from './components/Nav'
import Footer from './components/Footer'
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Console } from 'console';

// function withRouter(Component:any) {
//   function ComponentWithRouterProp(props:any) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return (
//       <Component
//         {...props}
//         router={{ location, navigate, params }}
//       />
//     );
//   }

//   return ComponentWithRouterProp;
// }
//const NavWithRouter = withRouter(Nav)
//const isFirefox = React.createContext('isFirefox');
declare const InstallTrigger: any;
interface Iloading {
  loaded: boolean;
}
type State = {
  ddnconfig?: []
  errors?: any
};
type  MainProps = {
  ddnstyle?: string
  css?: string
};
let appState:State
let mainProps: MainProps
let ddnstyleAttr: string

export default function  App() {
const [loaded, setLoaded] = useState<boolean | null>(false);
//let ddnconfig  = {}
const [ddnconfig, setDdnconfig] = useState<Object | null>(false);

  useEffect(() =>{
    let baseURL;
    // TO  DOFUND Anither way then window if posible
    let location_hostname = window.location.hostname
    //location_hostname = 'webddn.com'

    let first_domain = location_hostname.split('.').reverse()[1]
    let second_domain = location_hostname.split('.').reverse()[2]
    let is_www =  'www' === location_hostname.split('.')[0] ? true : false;

    let extension = location_hostname.split('.').reverse()[0]

    console.log(API.defaults.baseURL);
         console.log('location_hostname');
     console.log(location_hostname);
     console.log(location_hostname.split('.').reverse()[1]);
     console.log('first_domain');
     console.log(first_domain);
     console.log('is_www');
     console.log(is_www);

    if(location_hostname){
        if( second_domain !== undefined && location_hostname !== '192.168.86.99')
        {
          if(is_www)
          {
          baseURL= 'https://api.'+first_domain+'.'+extension+'/api/'+first_domain
          console.log('second baseURL');
          console.log(baseURL);
          }
          else {
          baseURL= 'https://api.'+second_domain+'.'+first_domain+'.'+extension+'/api/'+first_domain
          console.log('second baseURL');
          console.log(baseURL);
          }

        }
        else if( first_domain === 'webddn'){
          if(is_www)
          {
          baseURL= 'https://www.api.'+''+first_domain+'.'+extension+'/api/'+first_domain
          console.log('second baseURL');
          console.log(baseURL);
          }
          else {
          baseURL= 'https://api.'+first_domain+'.'+extension+'/api/'+first_domain
         console.log('IF API');
         console.log('first_domain baseURL');
         console.log(baseURL);
          }
        }
        else if( location_hostname === 'localhost'){
          baseURL= 'http://localhost:3001/api/webddn'
         }        
        else {
          console.log('ELSE API');

          //  TO DO  make the dynamic an create better domain based algorigthm
          baseURL= 'api/webddn'
        }
      }
    //console.log('asssssssssssssssssssssssssssssssss API');
    //console.log(baseURL);
//
  //  console.log(API.defaults.baseURL);

    API.defaults.baseURL = baseURL

    const getConfig = async () => {


      console.log(API.defaults.baseURL);
      API
        .get('/configs')
        .then(response =>
        {
          //ddnconfig = JSON.parse(response.data)
          //ddnconfig = response.data
              console.log('222222222222222ddnconfig[0]')
                  console.log(response.data[0].Landing[0])
                  let abcstr2 = JSON.stringify(response.data)
                  console.log(abcstr2)
                  // this for a user that loging now has the ablilty
                  //1.edit swap pictures or reomve
                  // 2 later on will be able to drag and drop images
    
                  response.data[0].Landing[0].isFirefox = typeof InstallTrigger !== 'undefined'
    
                  //response.data[0].edit=true;
            setDdnconfig(response.data[0]);
          setLoaded(true)
          return response.data[0];
        }
    
        )
        .catch(error => appState ={errors:error})
    
    };
    if(!loaded)
    {getConfig()}

    const updateAppState: any = () => {
      console.log('updateAppState');
      
        //const { isLoading, ddnconfig } = this.state
        console.log('window.location.pathname');
        console.log(window.location.pathname);
      
      if(window.location.pathname ==="/")
      {
        ddnstyleAttr= "App-main"
      }
      else {
        ddnstyleAttr= "App-main"
        }
      
        console.log('ddnstyleAttr');
        console.log(ddnstyleAttr);
      
      }
     updateAppState()

  
  },[])
  
  console.log('appState.isLoading');

  return (
    <div className="App">
    <header className="App-header-landing">
      <Nav title="Navigation" />
    </header>
      <Main ddnstyle={ddnstyleAttr}  css={ddnstyleAttr}  ddnLanding={ddnconfig} />
    <Footer />
  </div>
  )
}






