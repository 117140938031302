import React, { Component }  from 'react'
import Hero from './Hero';
import webddnresponsive from '../images/design_across_all_platforms_ddn.png';
import webddntech from '../images/DDNTECHNOLOGY2.png';
import pwa from '../images/platform-pwa-progressive-web-app.svg';
import electronjs from '../images/platform-electronjs-desktop.svg';
import appleios from '../images/platform-apple-ios.svg';
import androidgoogle from '../images/platform-android-google.svg';
import javascripticon from '../images/platform-javascript.svg';
import windowsicon from '../images/platform-windows.svg';
import buyerpromotion from '../images/marketingpromotions.svg';
import requireContext from 'require-context.macro';
import { connect } from 'react-redux'
import { AnyPtrRecord } from 'dns';
import { string } from 'yargs';


//import { addTodo } from "../redux/actions";

type Props = {
  testr?: any
  isFirefox?:boolean
  isAuthed?:boolean

  children?: React.ReactNode
};
const images = requireContext('../images', true,/\.images\.tsx$/)
const dc:string = document.cookie;


function getCookie(name:string) {
  let begin:number
  let prefix:string
  let end:number
  prefix = name + "=";
  begin = dc.indexOf("; " + prefix)
  if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
  }
  else
  {
      begin += 2;
      end = document.cookie.indexOf(";", begin);
      if (end == -1) {
      end = dc.length;
      }
      return decodeURI(dc.substring(begin + prefix.length, end));

  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
}

/* const clickListener: any(e:any, x:any) {
  var clickedElement;
      clickedElement = e.target;
      let ab = {
       tagName: clickedElement.tagName ,
        className: clickedElement.className,
        edit:true

      }
      console.log('ab.edit');

console.log(ab.edit);

return ab
          //console.log(clickedElement.tagName.src);
} */
export default function Landing({testr, children}: Props): React.ReactElement {

  // constructor(props) {

  //   super(props)
  // //  this.props.addTodo("test");

  //   };
  //render(){

    let globalClick:any ={};
 
    // TO DO put back tempoary disable edit to true also think about using Redux for this or react hooks
    //globalClick.edit= true;
 
 
    var myCookie = getCookie("ddnsessiontoken");
 
    if (myCookie == null) {
        // do cookie doesn't exist stuff;
        globalClick.edit= false;
 
    }
    else {
        // do cookie exists stuff
        globalClick.edit= true;
 
    }
 
 if( testr?.Landing) {
    console.log("first element of tutors array",  testr.Landing[0])
    // anything you can acccess now of this element
    //const graphImage = require(this.props.testr.Landing[0].ddnHero.image.src)
 
 }
    return(
 
 
     <div>
 
       {testr.Landing ?   <Hero ddnHero={testr.Landing[0].ddnHero} isFirefox={testr.Landing[0].isFirefox} edit={globalClick.edit} ></Hero> :testr.Landing}
       <div className="ddnbuttonContanier ddn-text-aligh-center">
         <a className="ddnbutton" href="guide/quickstart">Get Started </a>
       </div>
       <div className="App-content-landing-container" >
         <div className="App-content-landing" >
           <div className="App-promo" >
             <link rel="preload" as="image" href={webddnresponsive} />
             <img src={webddnresponsive} className="App-title-reg-landing" width="100%" height="100%" alt="WEB DDN TECHNOLOGY" />
           </div>
           <div className="App-promo-text" >
             <div className="text-headline" >
               DESIGN ACROSS ALL PLATFORMS
             </div>
             <p>
               We at WEB DDN are recognized for clean, crisp, responisive, mobile, well-organized and attractive web pages. We have extensive knowledge on organizing large amounts of content into well-ordered sites for easy navigation.
             </p>
           </div>
         </div>
         <hr/>
         <div className="App-content-landing" >
           <div className="App-promo-text" >
           <div className="text-headline" >
             DEVELOP WITH SPEED & PERFORMANCE
           </div>
           <p>
             Our many years of web design experience have kept us up to date on the latest web trends and technologies and possess the talent for applying this knowledge to web site design and development now.
           </p>
           </div>
           <div className="App-promo" >
           <img src={webddntech} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
           </div>
         </div>
         <hr/>
         <div className="App-content-landing" >
           <div  className="App-promo2">
             <img src={appleios} className="App-logo-medium" alt="WEB DDN Apple iOS" />
             <img src={androidgoogle} className="App-logo-medium" alt="WEB DDN Google Android" />
             <img src={windowsicon} className="App-logo-medium" alt="WEB DDN Electron" />
             <img src={pwa} className="App-logo-medium" alt="WEB DDN Progressive Web App" />
             <img src={javascripticon} className="App-logo-medium" alt="WEB DDN Google Android" />
           </div>
           <div className="App-promo-text" >
             <div className="text-headline" >
               We help business design, build, ship beautiful cross-platform hybrid and native apps
             </div>
             <p>
               Every project will be assessed on an individual basis. Free initial consultation with each client will assist to determine pricing structure, duration of contract and overall content and layout of your web site.
             </p>
           </div>
 
         </div>
         <hr/>
         <div className="App-content-landing" >
           <div className="App-promo-text" >
           <div className="text-headline" >
             Marketing and Promotions
           </div>
           <p>
             10X higher engagement than regular ad formats. Outsmart Your Competition. Reach Potential Consumers Everywhere. Reach more relevant audiences across the web’s top publishers. Attract engaged traffic to your blogs, articles, videos, apps and more. Increase awareness, leads and sales
           </p>
           </div>
           <div className="App-promo" >
           <img src={buyerpromotion} className="App-title-reg-landing" alt="WEB DDN TECHNOLOGY" />
           </div>
         </div>
 
       </div>
     </div>
     )
   //}





}
/* export default connect(
  null,
  { addTodo }
)(Landing);
 */