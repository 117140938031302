import React from 'react'
//import { setNestedObjectValues } from '../utils/utils';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import API from '../api';
import { useNavigate } from 'react-router-dom';

//import * as yup from 'yup'; // for everything
type Props = {
  field?: any
  form?: {errors?:any}
  children?: React.ReactNode
};
let e:any

const handleKeyDown = (e: any) => {

  // Prevent spaces being entered
  if (e.key === " ") {
    e.preventDefault();
  }
};


function getStyles(errors: any, fieldName: string | string[]) {
  if (getIn(errors, fieldName)) {
    return "errors"
  }

}
function createCookieInHour (cookieName: string, cookieValue: string, hourToExpire: number) {
  console.log('createCookieInHour');

    let date = new Date();
    date.setTime(date.getTime()+(hourToExpire*60*60*1000));
    console.log( cookieName + " = " + cookieValue + "; expires = " +date.toUTCString());

    document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toUTCString();
}
// export default function Footer({field, form, children}: Props): React.ReactElement {
// const App = ({ children }: Props) => <div>{children}</div>;
// const CustomInput = ({ children }: Props) => 
// <div>
// <input {...children?.field}  onKeyDown={handleKeyDown} className={getStyles(children.errors, children.field.name)} />
// </div>;
//function CustomInput({ field, form: { errors } }) {


function CustomInput({field, form}: Props): React.ReactElement {

  return <div>
    <input {...field}  onKeyDown={handleKeyDown} className={getStyles(  form?.errors, field.name)} />
  </div>
}
const loginState = {
  email: "",
  password: "",
  logincheck: true
}
function Login({children}: Props): React.ReactElement {
      let baseURL;
      // TO  DOFUND Anither way then window if posible
      let location_hostname = window.location.hostname
      //location_hostname = 'webddn.com'

      let first_domain = location_hostname.split('.').reverse()[1]
      let second_domain = location_hostname.split('.').reverse()[2]
      let is_www =  'www' === location_hostname.split('.')[0] ? true : false;

      let extension = location_hostname.split('.').reverse()[0]

      console.log(API.defaults.baseURL);
           console.log('location_hostname');
       console.log(location_hostname);
       console.log(location_hostname.split('.').reverse()[1]);
       console.log('first_domain');
       console.log(first_domain);
       console.log('is_www');
       console.log(is_www);
       if(location_hostname){
        if( second_domain !== undefined && location_hostname !== '192.168.86.99')
        {
          if(is_www)
          {
          baseURL= 'https://www.api.'+second_domain+'.'+first_domain+'.'+extension+'/api/'+first_domain
          console.log('second baseURL');
          console.log(baseURL);
          }
          else {
          baseURL= 'https://api.'+second_domain+'.'+first_domain+'.'+extension+'/api/'+first_domain
          console.log('second baseURL');
          console.log(baseURL);
          }

        }
        else if( first_domain === 'webddn'){
          if(is_www)
          {
          baseURL= 'https://www.api.'+''+first_domain+'.'+extension+'/api/'+first_domain
          console.log('second baseURL');
          console.log(baseURL);
          }
          else {
          baseURL= 'https://api.'+first_domain+'.'+extension+'/api/'+first_domain
         console.log('IF API');
         console.log('first_domain baseURL');
         console.log(baseURL);
          }
        }
        else if( location_hostname === 'localhost'){
         baseURL= 'http://localhost:3001/api/webddn'
        }
        else {
          console.log('ELSE API');

          //  TO DO  make the dynamic an create better domain based algorigthm
          baseURL= 'api/webddn'
        }
      }
      API.defaults.baseURL = baseURL

//    }
//    render(){
      //const {navigate} = this.props;
      const navigate = useNavigate();

      const { email, password } = loginState;
       return(
         <>

        <div>
        <div className="ddnTextHeadline2" >Log in </div>


          <div className="App-content-landing-container" >
          {/*
            <div class="ddnbuttonContanier ddn-text-aligh-right"><button className="ddn-button" href="signup">Sign Up</button></div>

            <label class="text-headline" htmlFor="newuser"> <a href="/signup">Create an account </a>New to Webdn </label>*/}

          <Formik
             initialValues={{ email: '', password: '' }}
             validate={values => {
               let errors:any
               errors = { email: '', password: '' };
               
               if (values.email===undefined) {
                 errors.email = 'Required';
               } 
              else if(values.email=='')
              {
                errors.email = 'Required';

              }
               else if (
                 !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
               ) {
                 errors.email = 'Invalid email address';
               }
               if(values.password=='')
               {
                 errors.password = 'Required';
                }
                if(errors.password!='' || errors.password!='')
               return errors;
             }}
             onSubmit={(values, { setSubmitting, setErrors }) => {
               /* setTimeout(() => {
                 alert(JSON.stringify(values, null, 2));
                 setSubmitting(false);
               }, 400);
http://localhost:3001/api/webddn/login?api_key=a194981-b541-416e-9351-dd0c3d572647&=
               */
let errors:any;
errors = { email: '', password: '' };

console.log('onSubmit');

               setSubmitting(false);
               values.email.trim();
               console.log(API.defaults.baseURL);
               API
                 .post('/login?api_key=a194981-b541-416e-9351-dd0c3d572647&',{
                   uname: values.email,
                   pwd: btoa(values.password)
                 }
                  )
                 .then(response =>
                 {
                //   alert(JSON.stringify(values, null, 2));
                createCookieInHour('ddnsessiontoken', response.data.response.sessiontoken, 5);

                   //ddnconfig = JSON.parse(response.data)
                   //ddnconfig = response.data
                       console.log('222222222222222ddnconfig[0]')
                       console.log(response.data.response)
                       console.log(response.data.response.sessiontoken)

                           let abcstr2 = JSON.stringify(response.data.response.response)
                           console.log(abcstr2)
                           navigate('/');

                           // this for a user that loging now has the ablilty
                           //1.edit swap pictures or reomve
                           // 2 later on will be able to drag and drop images
                           //response.data[0].Landing[0].isFirefox = typeof InstallTrigger !== 'undefined'
                           //response.data[0].edit=true;
                   /*this.setState({
                     ddnconfig: response.data[0],
                     isLoading: false
                   })*/
                   //navigate.push('/features');
//this.context.navigate.push('/features');
                  // this.props.navigate.push('/features');
                   //this.navigate.pushState(null, '/features');


                 }

                 )
                   .catch(  error =>
                     {
                       console.log('errors');
//this.setState({logincheck:false});
loginState.logincheck =false
errors.password="The email or password you’ve entered is incorrect.";

console.log(errors);

                       setErrors(errors);
                       return errors;

                     //errors.login_check="Invalid login";
                  // errors.login_check='Invalid login'
                //setErrors(errors);

                //error.setFieldError('login_check', Invalid login');
                      // this.setState({error, isLoading:false})
                     }
                 )
                    
             }}
           >
             {({  isSubmitting }) => (
               <Form className="ddnLogin">


                  <label className="text-headline" htmlFor="email">Email:</label>
                 <Field component={CustomInput}  type="email" name="email"  placeholder="Enter your email" />
                 <div className="ddnErrorLabel">
                    <ErrorMessage name="email" />
                 </div>
                 <label className="text-headline" htmlFor="email">Password:</label>

                 <Field type="password" name="password" placeholder="Enter your password" />
                 <div className="ddnErrorLabel">
                    <ErrorMessage name="password" />
                 </div>
                 <div className="ddnbuttonContanier">
                   <button type="submit" disabled={isSubmitting}>
                      Log in
                    </button>
                  </div>
                  <div className="ddnErrorLabel">
                  {/* !this.state.logincheck ? <a href="/signup"> Create an account </a>: null */}

                  </div>

               </Form>
             )}
           </Formik>
          </div>
        </div>

        </>
        )
//      }
}
export default Login;
