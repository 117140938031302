import React from 'react';
import API from '../api';

class DDNHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      commands: [],
      redocommands: [],
      lastCommand:{},
      ddnTextHeadline: this.props.ddnTextHeadline,
      input: '',
      edit: false,
      isEditable: this.props.edit
    };

  }

  getCookie = (name) => {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
      }
      else
      {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
          end = dc.length;
          }
      }
      // because unescape has been deprecated, replaced with decodeURI
      //return unescape(dc.substring(begin + prefix.length, end));
      return decodeURI(dc.substring(begin + prefix.length, end));
  }
  //function saveMemento() {

  //}

  // undo = name => event => {
  //   console.log('undo');
  //   console.log('mementos');
  //   console.log('this.state.mementos');
  //   console.log(this.state.mementos);
  //   this.state.lastMemento = this.state.mementos.pop();
  //   console.log(this.state.mementos);
  //
  //
  //   this.state.ddnTextHeadline= this.state.lastMemento ? this.state.lastMemento : this.state.ddnTextHeadline
  //   this.setState({ [name]: this.state.mementos});
  //   console.log( this.state.ddnTextHeadline);
  // }
  undo2 = name => event => {
    console.log('ubdo');

    this.setState({ [name]: this.state.commands});
      let value = this.state.ddnTextHeadlineValue.split('')
    this.state.lastCommand = this.state.commands.pop()
    // this.setState((state, props) => ({
    //   lastCommand: state.commands.pop()
    // }));

    //console.log( this.state: this.state.commands.pop());

  //  this.state.redocommands.push(lastCommand )


    if (!this.state.lastCommand) return

    switch (this.state.lastCommand.inverse.type) {
      case 'remove':
      value.splice(this.state.lastCommand.inverse.index, 1)
      break;
    }

    this.state.ddnTextHeadlineValue = value.join('')

  }
  //console.log('redo');
  //this.state.lastMemento = this.state.redocommands.pop();
  redo2 = name => event => {
      console.log('redo');
    console.log(this.state.redocommands);


    this.state.ddnTextHeadline= this.state.lastMemento ? this.state.lastMemento : this.state.ddnTextHeadline
    this.setState({ [name]: this.state.redocommands});
    console.log( this.state.ddnTextHeadline);
    let value = this.state.ddnTextHeadline.split('')
    const lastCommand = this.state.redocommands.pop()
    this.state.redocommands.push(lastCommand)
    if (!lastCommand) return

    switch (lastCommand.inverse.type) {
      case 'remove':
      value.splice(lastCommand.inverse.index, 1)
      break;
    }
      this.state.commands.push(lastCommand)

  }
  updateConfig = async (ddnTextHeadline) => {

    console.log('updateConfig')
    console.log(ddnTextHeadline)
    console.log('updateConfig')

    //require(url);
    let ddnconfig;
    //var myObject = JSON.parse(myjsonstring);
    let updateHereTest = {
        "Landing.0.ddnHero.ddnTextHeadline" : ddnTextHeadline
    }
    // let baseURL;
    //
    // if(this.props.location.hostname.split('.').reverse()[1]!='webddn'){
    //   baseURL= 'api'+this.props.location.hostname.split('.').reverse()[1]+'.'+this.props.location.hostname.split('.').reverse()[0]+'api/'+this.props.location.hostname.split('.').reverse()[1]
    // }
    // else {
    //   //  TO DO  make the dynamic an create better domain based algorigthm
    //   baseURL= 'api/webddn'
    // }
    // console.log('asssssssssssssssssssssssssssssssss API');
    //
    // console.log(API);
    // API.defaults.baseURL = baseURL
       var myCookie = this.getCookie("ddnsessiontoken");
       console.log('myCookie');
       console.log(myCookie);

    API
      .put('config/5ead266ae9f699a6cc93c535',
        updateHereTest, {
          headers: {
            Authorization: "Bearer " + myCookie
          }

      })
      .then(response =>
      {   console.log(response);
        console.log(response.data);
      }

      )
      .catch(error => this.setState({error, isLoading:false}))

  };

  handleClick = name => event => {
    console.log('this.state.isEditable');
    console.log(this.state.isEditable);


    if(this.state.isEditable){
    console.log('this.handleClick[name]');
    console.log('event.target');
    console.log(event.target);
    this.setState({ [name]: event.target.value, ddnTextHeadline:this.props.ddnTextHeadline, edit:true });
    console.log(' this.setState');
    console.log(this.setState);
    console.log(name);
    }
  }
  handleMouseLeave = name => event => {

    console.log('this.handleMouseLeave[name]');
    this.setState({ [name]: event.target.value, edit:false });
    console.log(' this.setState');
    console.log(this.setState);
    console.log(name);

  }
  // handleChange = ddnTextHeadline => event => {
  //
  //   console.log('this.handlers[ddnTextHeadline]');
  //   this.setState({ [ddnTextHeadline]: event.target.value });
  //   this.state.ddnTextHeadline = event.target.value;
  //   this.state.mementos.push(event.target.value)
  //   console.log('_____ this.setState');
  //   console.log(this.state);
  //   console.log(this.state['nameabc']);
  //
  //   console.log(ddnTextHeadline);
  //
  // }
  handleChange2 = ddnTextHeadline => event => {
    console.log('handleChange2');

    this.setState({ [ddnTextHeadline]: event.target.value });
    console.log('event.target.value');
    console.log(  event.target.value);
    this.updateConfig( event.target.value )
    this.state.commands.push({
      // the action is also saved for implementing redo, which
      // is not implemented in this example.
      action: { type: 'add', key: event.key, index: event.target.selectionStart },
      inverse: { type: 'remove', index: event.target.selectionStart }
    })
    console.log('this.handlers[ddnTextHeadline]');
    this.state.ddnTextHeadline = event.target.value;
    console.log('_____ this.setState');
    console.log(this.state);
    console.log(this.state['nameabc']);

    console.log(ddnTextHeadline);

  }
  handleKeydown = ddnTextHeadline => event => {
    console.log('handleKeydown]');
    console.log('state]');
    console.log(  this.state);

      this.setState({ [ddnTextHeadline]: event.target.value });
          console.log(  this.state);
    this.state.commands.push({
      // the action is also saved for implementing redo, which
      // is not implemented in this example.
      action: { type: 'add', key: event.key, index: event.target.selectionStart },
      inverse: { type: 'remove', index: event.target.selectionStart }
    })


//this.state.ddnTextHeadline = event.target.value+ event.key;
  }
  render()
  {

    // console.log('this.props.ddnTextHeadline')
    // console.log(this.props.ddnTextHeadline)
    // console.log('this.state')
    // console.log(this.state)
    //
    // console.log(this.props.isFirefox)
   console.log('this.state.isEditable')
     console.log(this.state.isEditable)
    if(!this.state.edit)
    {
        if(!this.state.isEditable){
          return (
            <>
            <div className="ddnTextHeadline" onMouseEnter={this.handleClick('nameasss')}  >{ this.props.ddnTextHeadline}</div>
            </>
          )
        }
        else {
        return (
          <>
          <div className="ddnTextHeadline" onMouseEnter={this.handleClick('nameasss')}  >{ this.props.ddnTextHeadline}</div>
          <button onClick={this.undo2()}>Undo</button>
          <button onClick={this.redo2()}>Redo</button>
          </>
        )
      }
    }
    else {
      if(!this.props.isFirefox)
      {
        return (
          <>

          <input className="ddnTextHeadlineInput" type='text' defaultValue={this.state.ddnTextHeadline}  onKeyDown={this.handleKeydown('ddnTextHeadlineValue')} onChange={this.handleChange2('ddnTextHeadline')}></input>
          <button onClick={this.undo2()}>Undo</button>
          <button onClick={this.redo2()}>Redo</button>
          </>
        )
      }
      else {
        return (
          <>

          <div className="ddnTextHeadline" contenteditable="true" onMouseLeave={this.handleMouseLeave('ddnTextHeadline')} onKeyDown={this.handleKeydown('name')} >{this.state.ddnTextHeadline}</div>
          <button onClick={this.undo2()}>Undo</button>
          <button onClick={this.redo2()}>Redo</button>
          </>
        )
      }
    }
  }
}
export default DDNHeading
