import React, {Component} from 'react';
import webddnmenu from '../images/ddn-hambuger-menu.svg';
import webddnsignin from '../images/WEBDDNSignIn.svg';
import webddnlogowhiteoutline from '../images/DDN_LOGO_White_Outline.svg';

type Props = {
    title?: string

  };

//import { Route } from 'react-router';
//import {withRouter} from 'react-router-dom';
//import '../nav.css';

export default function Nav({title}:Props):JSX.Element {


  //render()
  //{

let isLanding =  window.location.pathname === "/"
//isLanding = true
console.log('isLanding');

console.log(isLanding);
return(
      <div>


 <div className="nav_containter">
  <label htmlFor="show-menu" className="show-menu"><img src={webddnmenu} className="ddn-logo-menu"  alt="icon" />
  </label>
  {!isLanding &&
        <>
      <a   href="/"><img src={webddnlogowhiteoutline} className="App-logo-link"  alt="icon" />
      {/*<img src={webddnicon} className="App-logo-link"  alt="icon" />*/}
</a>

</>
}

<a className="topLinks" href="/login"><img src={webddnsignin} className="App-logo-link"  alt="Log IN" /></a>

<a className="topLinks" href="/signup">Sign up</a>
  <input type="checkbox" id="show-menu" role="button"></input>
  <ul id="menu">
      <li  className="App-ddn-logo-li">
      <a   href="/"><img src={webddnlogowhiteoutline} className="App-ddn-logo"  alt="icon" /> </a>

          {/*<ul class="hidden">
              <li><a href="/mission">Design</a></li>
          </ul>*/}
      </li>

      <li>
          <a href="/features">Features</a>
          {/*<ul class="hidden">
              <li><a href="/mission">Design</a></li>
          </ul>*/}
      </li>
      <li>
          <a href="pricing/">Pricing</a>
      </li>

      <li><a href="/signup">Sign up</a></li>

      <li  className="App-ddn-logo-li">
      <a   href="/login"><img src={webddnsignin} className="App-ddn-logo"  alt="icon" /> </a>

          {/*<ul class="hidden">
              <li><a href="/mission">Design</a></li>
          </ul>*/}
      </li>
  </ul>
  </div>


      </div>
    )
 // }

}
