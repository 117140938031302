import axios from 'axios';

const api = axios.create({

  //if(location.hostname.split('.').reverse()[1]!='webddn'){
    //baseURL: 'api'.location.hostname.split('.').reverse()[1]+'.'location.hostname.split('.').reverse()[0]+'api/'.location.hostname.split('.').reverse()[1]'
  //}
  //else {
    //  TO DO  make the dynamic an create better domain based algorigthm
//    baseURL: 'api/webddn'

  //}
  ////else if (location.hostname.split('.').length == 1 )



});
export default api;
