import React, {Component} from 'react';
//import logo from '../logo.svg';
//import webddn from '../WEBDDN.svg';
import WEDDNICONLOGO from '../images/WEDDNICONLOGO.svg';
//import webddntech from '../images/DDNTECHNOLOGY2.png';
//import {withRouter} from 'react-router-dom';
type Props = {
  children?: React.ReactNode
};

function CurrentPowerDate(){
  var tempDate = new Date();
  //var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
  const currDate = tempDate.getFullYear() ;
  return (
  currDate
  );
}
export default function Footer({children}: Props): React.ReactElement {


  //render()
  //{


return(
  <footer className="ddnFooter">
      <div  className="ddnFooterDiv" >
        <div>
          {/*this.props.location.pathname*/}
        </div>

          Powered by  <div className="ddnPower">     </div>
           <link rel="preload" as="image" href={WEDDNICONLOGO} />
           <img src={WEDDNICONLOGO} className="ddnPowerLogo" alt="logo" /> © 2010-{CurrentPowerDate()}

      </div>
    </footer>
    )
  //}

}
