
import React from 'react';
//import HelloWorld from './HelloWorld';
import DDNHeading from './DDNHeading';
//const images = require('../lib/bundle-loader')
//import bundleLoader from '../lib/bundle-loader'
import requireContext from 'require-context.macro';
const images = requireContext('../images', true);
//let images = bundleLoader.importFiles()
class Hero extends React.Component {

  // handleChange = name =>
  // {
  //   console.log('this.handlers[name]');
  //   //console.log(this.handlers);
  //   //console.log('this.props.ddnTextHeadline');
  //
  //   //console.log(this.props.ddnTextHeadline);
  //   if (!this.handlers[name])
  //   {
  //     this.handlers[name] = event =>
  //     {
  //       this.setState({ [name]: event.target.value });
  //     };
  //   }
  //   return this.handlers[name];
  // }

   // handleChange = name => event => {
   //
   //   console.log('this.handlers[name]');
   //      // this.setState({ [name]: event.target.value });
   //       console.log(' this.setState');
   //       console.log(this.setState);
   //     console.log(name);
   //
   //     }
  // render()
  // {
  //  return (      <input onChange={this.handleChange('name')}/>      <input onChange={this.handleChange('description')}/>    )
  // }
//handleClick = (event) => {    console.log('this.state'); console.log(this.props.ddnTextHeadline);  }
  render()
  {
  console.log('this.context.ddnHero')

  console.log(this.props.isFirefox)

      return (

        <div className="ddnHero">
          <img src={images(`./${this.props.ddnHero.image.src}`)} className="App-icon-reg-landing" alt="logo" />
              <DDNHeading ddnTextHeadline={this.props.ddnHero.ddnTextHeadline} isFirefox={this.props.isFirefox} edit={this.props.edit}> </DDNHeading>
        </div>

      )

  }

}
export default Hero;
