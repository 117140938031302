import React from 'react'
import {BrowserRouter as Router, Routes , Route } from 'react-router-dom'
//import Design from './components/Design'
//import Develop from './components/Develop'
//import Now from './components/Now'
//import Mobile from './components/Mobile'
//import Quote from './components/Quote'
import Login from './components/Login'
import Landing from './components/Landing'

type Props = {
  ddnstyle?: string
  ddnLanding?:any
  children?: Object
  css?:string
};

export default function Main({ddnstyle, ddnLanding, children}:Props):JSX.Element {
  return (
    <Router>
    <main className={ddnstyle} >
      <Routes >
        <Route path='/login'  element={<Login/>} />

        {/* <Route path='/design' component={Design}/>
        <Route path='/develop' component={Develop}/>
        <Route path='/nice' component={Now}/>
        <Route path='/mobile' component={Mobile}/>
        <Route path='/quote' component={Quote}/> */}
        <Route path='/'
          element={ <Landing {...children} testr={ddnLanding} isFirefox={ddnLanding.isFirefox} isAuthed={true}/>}
        />
      </Routes >
    </main>
    </Router>
  )
      }
