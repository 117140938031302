import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
//import requireContext from 'require-context.macro';


//import { createStore } from 'redux';
//import postReducer from './reducers/postReducer';
import { Provider } from 'react-redux';
//const store = createStore(postReducer);
//import store from "./redux";
import reportWebVitals from './reportWebVitals';

var clarendonBTBlack = './fonts/ClarendonBT-Black.woff2';
var humanist777BT = './fonts/Humanist777BT-BlackB.woff2'
//import './fonts/ClarendonBT-Black.woff2';
//import './fonts/Humanist777BT-BlackB.woff2'

//const Appcss = requireContext('App.css', true);
var loadRelHead = function(href: string) {
  var tag = document.createElement('link');
  tag.rel = 'preload';
  tag.as='font';
  tag.href = href;
  tag.type='font/woff2';
  tag.crossOrigin='';

  document.getElementsByTagName('head')[0].appendChild(tag);
}
loadRelHead(clarendonBTBlack);
loadRelHead(humanist777BT);
loadRelHead('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2');
loadRelHead('https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2')
loadRelHead('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2')
loadRelHead('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2')

WebFont.load({
  google: {
    families: [ 'Roboto:300,400,500,600,700', 'sans-serif' ]
  },
  custom: {
    families: ['Clarendon Blk BT','Humnst777 Blk BT'],
    urls: ['./App.css']

  }

});

//loadRelHead('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


//ReactDOM.render(<BrowserRouter><Provider store={store}><App /></Provider></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
